<!--运行维护-数据异常统计-->
<template>
  <div class="abnormal_data_page">
    <!-- 工具条 -->
    <el-form :inline="true" size="mini">
      <el-form-item>
        <el-input
          v-model="postdata.LocaleName"
          placeholder="监测点"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item>
        <el-select v-model="postdata.Type" @change="searchData" placeholder="设备状态" clearable>
            <el-option label="数据异常偏小" :value="3"></el-option>
            <el-option label="数据异常偏大" :value="4"></el-option>
            <el-option label="数据偏移异常" :value="6"></el-option>
            <el-option label="上线时间过短" :value="5"></el-option>
            <el-option label="异常离线报警" :value="2"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-cascader
          placeholder="所属单位"
          v-model="postdata.Owner"
          :options="customerTree"
          :props="customerProps"
          @change="searchData"
          clearable
          filterable
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          type="daterange"
          v-model="PanicRangeTime"
          @change="searchData"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item>
        <el-select v-model="postdata.Status" @change="searchData" placeholder="操作状态" clearable>
            <el-option label="待处理" :value="1"></el-option>
            <el-option label="正常" :value="3"></el-option>
            <el-option label="已派单" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理日期">
        <el-date-picker
            type="daterange"
            v-model="HandleRangeTime"
            @change="searchData"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item> -->
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="searchData"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table
      :data="dataObj.list"
      size="mini"
      border
      highlight-current-row
      :loading="loading"
      style="width: 100%"
    >
      <el-table-column type="index" label="#" align="center" width="55" />
      <el-table-column
        prop="LocaleName"
        label="监测点"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column
        prop="MaintainerName"
        label="运维人员"
        width="150"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column
        prop="AbnormalOfflineDays"
        label="异常离线天数"
        align="center"
      />
      <el-table-column
        prop="TooSmallDays"
        label="数据偏小天数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="TooBigDays"
        label="数据偏大天数"
        align="center"
      />
      <el-table-column
        prop="TooShortOnlineDays"
        label="上线时间偏短天数"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column
        prop="ShiftDays"
        label="数据漂移天数"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column
        prop="OfflineCausedByShopDays"
        label="店家原因导致离线天数"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column
        prop="DetectorFailureDays"
        label="监测仪故障天数"
        show-overflow-tooltip
        header-align="center"
      />
      <el-table-column
        prop="ShopClosedDays"
        label="歇业(装修)天数"
        show-overflow-tooltip
        header-align="center"
      />
    </el-table>
    <!-- 工具条 -->
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="postdata.Page"
        :page-sizes="[20, 40, 100]"
        :page-size="postdata.PerPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataObj.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { parseTime } from "@/util/index";

export default {
  data() {
    return {
      postdata: {
        Page: 1, // 页码，自用
        PerPage: 10, // 页数，自用
        LocaleName: "", // 监测点名称
        Owner: [], // 所属单位
        BeginDate: undefined, // 异常日期开始时间
        EndDate: undefined, // 异常日期结束时间
      },
      PanicRangeTime: [], // 异常日期时间，自用
      HandleRangeTime: [], // 处理日期时间，自用,
      loading: false,
      dataObj: {
        list: [],
        pages: 0,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState(["customerTree"]),
    ...mapState({
      customerProps: (state) =>
        Object.assign({}, state.props, { label: "Org", checkStrictly: true }),
    }),
  },
  mounted() {
    this.netGetMessageList(); // 获取列表
  },
  methods: {
    /**
     * @description 日期格式化
     */
    dateFormatter(row, column) {
      return parseTime(row[column.property], "{y}-{m}-{d}");
    },
    /**
     * @description 获取列表
     */
    netGetMessageList() {
      this.loading = true;
      const postdata = JSON.parse(JSON.stringify(this.postdata));
      if (!this.PanicRangeTime || !this.PanicRangeTime.length) {
        const day = new Date();
        const yy = day.getFullYear();
        const mm = (day.getMonth() + 1) < 10 ? (`0${day.getMonth() + 1}`) : day.getMonth() + 1;
        const dd = day.getDate() < 10 ? `0${day.getDate()}` : day.getDate();
        const last = new Date(new Date().getTime() - 7 * 24 * 3600 * 1000);
        const lyy = last.getFullYear();
        const lmm = (last.getMonth() + 1) < 10 ? (`0${last.getMonth() + 1}`) : last.getMonth() + 1;
        const ldd = last.getDate() < 10 ? `0${last.getDate()}` : last.getDate();
        this.PanicRangeTime.push(`${lyy}-${lmm}-${ldd}`);
        this.PanicRangeTime.push(`${yy}-${mm}-${dd}`);
      }
      postdata["BeginDate"] = this.PanicRangeTime[0];
      postdata["EndDate"] = this.PanicRangeTime[1];
      postdata.Owner = postdata.Owner.join('/');
      this.$post("admin/queryLocaleDriftOffline", postdata)
        .then((res) => {
          this.dataObj.list = res.Content;
          this.dataObj.pages = res.resultsPageInfo.TotalPage;
          this.dataObj.total = res.resultsPageInfo.Total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /**
     * @description 查询
     */
    searchData() {
      this.postdata.Page = 1;
      this.netGetMessageList();
    },

    /**
     * @description 每页条数
     */
    handleSizeChange(pageSize) {
      this.postdata.Page = 1;
      this.postdata.PerPage = pageSize;
      this.netGetMessageList();
    },

    /**
     * @description 页码
     */
    handleCurrentChange(page) {
      this.postdata.Page = page;
      this.netGetMessageList();
    },
  },
};
</script>

<style lang="scss">
.abnormal_data_page {
  .page_box {
    padding: 30px 0;
    text-align: center;
  }
}
</style>
